"use client";

import * as Sentry from "@sentry/nextjs";
import NextError from "next/error";
import { useEffect, useRef } from "react";
import { ErrorBoundary } from "@sentry/react";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!errorTimeoutRef.current) {
      Sentry.captureException(error);
      errorTimeoutRef.current = setTimeout(() => {
        errorTimeoutRef.current = null;
      }, 3000);
    }

    // Cleanup function
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
        errorTimeoutRef.current = null;
      }
    };
  }, [error]);
  return <ErrorBoundary fallback={<NextError statusCode={0} />} data-sentry-element="ErrorBoundary" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <html>
        <body>{/* Error content goes here */}</body>
      </html>
    </ErrorBoundary>;
}